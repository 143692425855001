import http from '@/utils/http';

/**
 * 佣金规则
 */
export default {
  getList: {
    p: 'post,/manage/rebaterule/getlist',
    r: (data) => http({ url: '/manage/rebaterule/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/rebaterule/add',
    r: (data) => http({ url: '/manage/rebaterule/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/rebaterule/update',
    r: (data) => http({ url: '/manage/rebaterule/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/rebaterule/delete',
    r: (data) => http({ url: '/manage/rebaterule/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/rebaterule/getone',
    r: (data) => http({ url: '/manage/rebaterule/getone', method: 'post', data })
  },
  exportFile: {
    p: 'post,/system/export/user',
    r: (data) => http({ url: '/system/export/user', method: 'post', responseType: 'blob', data })
  }
};
