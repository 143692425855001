<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <div class="search">
      <avue-form ref="searchForm" v-model="searchData" :option="searchOption" @submit="searchChange" @reset-change="searchReset">
      </avue-form>
    </div>
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      :page.sync="page"
    >    
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button icon="el-icon-view" :size="size" :type="type" @click.stop="rowView(row)">查看</el-button>
      </template>
    </avue-crud>
    <el-drawer
      title="交易详情"
      :before-close="drawerInfoClose"
      :visible.sync="drawerInfoVisible"
      direction="ttb"
      size="100%"
    >
      <div class="details">
        <!--交易详情-->
        <div class="details-header">
          <div class="details-title">基础信息</div>
        </div>
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">流水号:</div>
            <div class="details-view-item-value">{{formDa.batch}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">推荐人:</div>
            <div class="details-view-item-value">{{formDa.referrer_name}}</div>
          </div>
           <div class="details-view-item">
            <div class="details-view-item-label">贷款产品:</div>
            <div class="details-view-item-value">{{formDa.product.title}}</div>
          </div>
           <div class="details-view-item">
            <div class="details-view-item-label">佣金规则:</div>
            <div class="details-view-item-value">{{formDa.rebate_rule}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">公司名称:</div>
            <div class="details-view-item-value">{{formDa.company}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">贷款人:</div>
            <div class="details-view-item-value">{{formDa.guest}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">贷款金额:</div>
            <div class="details-view-item-value">￥{{formDa.amount}}万元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">状态:</div>
            <div class="details-view-item-value">{{formDa.status_text}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">备注:</div>
            <div class="details-view-item-value">{{formDa.remark}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{formDa.create_time}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">更新日期:</div>
            <div class="details-view-item-value">{{formDa.update_time}}</div>
          </div>
          <div class="details-view-img">
            <div class="details-view-img-label">佐证材料:</div>
            <div class="details-view-item-value">

              <el-image style="width: 120px; height: 120px; margin-right:20px"
                v-for="(item, index) in formDa.material" :key="index"
                :src="item" 
                :preview-src-list="formDa.material">
              </el-image>
            </div>
          </div>
        </div>
        <div class="details-header">
          <div class="details-title">结算详情</div>
        </div>
        <!--团队详情-->
        <div class="details-view flex-column">
          <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane label="佣金列表" name="1">
              <avue-crud
                :data="rebateData"
                :option="rebateOption"
              >
              </avue-crud>
            </el-tab-pane>
            <el-tab-pane label="操作日志" name="2">
              <avue-crud
                :data="logData"
                :option="logOption"
              >
              </avue-crud>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
