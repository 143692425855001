import './list.scss';
import dataApi from '@/api/custmoer/customs.js';
import dataBankApi from '@/api/manage/bank';
import dataMemberApi from '@/api/custmoer/client.js';
import dataProductApi from '@/api/manage/product';
import dataRelateRuleApi from '@/api/manage/rebateRule';
import dataRebateApi from '@/api/manage/rebate.js';

const dataStatus = [
  {
    label: '已作废',
    value: 0,
    disabled: false
  },
  {
    label: '提交成功',
    value: 1,
    disabled: false
  },  
  {
    label: '待审核',
    value: 2,
    disabled: false
  },
  {
    label: '登记驳回',
    value: 3,
    disabled: false
  },
  {
    label: '确认成功',
    value: 4,
    disabled: false
  },
  {
    label: '完结',
    value: 5,
    disabled: false
  }          
];
export default {
  name: 'trading-list',
  components: {},
  data() {
    return {
      dataApi: dataApi,
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100],
        currentPage: 1
      },
      activeName: '1',
      dialogVisible: false,
      drawerVisible: false,
      dialogInfoVisible: false,
      drawerInfoVisible: false,
      productList: [],
      rebateRuleList: [],
      memberList: [],
      formDa: {
        bank: {
          title: ''
        },
        product: {
          title: ''
        }
      },
      formOp: {
        column: [
          {
            type: 'input',
            label: '交易流水号',
            span: 12,
            display: true,
            disabled: true,
            prop: 'batch'
          },
          {
            type: 'date',
            label: '放款日期',
            span: 12,
            display: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'date_loan'
          },
          {
            type: 'input',
            label: '贷款人',
            span: 12,
            display: true,
            prop: 'guest',
            rules: [
              {
                required: true,
                message: '贷款人不能为空',
                trigger: 'blur'
              }
            ]
          },
          {
            label: '贷款产品',
            prop: 'product_id',
            span: 12,
            formslot: true,
            rules: [{
              required: true,
              message: '请选择贷款产品',
              trigger: 'blur'
            }]
          },
          {
            type: 'input',
            label: '企业名称',
            span: 12,
            display: true,
            prop: 'company',
            rules: [
              {
                required: true,
                message: '企业名称不能为空',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'input',
            label: '贷款金额',
            span: 12,
            display: true,
            prop: 'amount',
            rules: [
              {
                required: true,
                message: '金额必须填写'
              }
            ],
            required: true,
            append: '万元'
          },
          {
            label: '推荐人',
            prop: 'referrer',
            type: 'table',
            span: 12,
            dialogWidth: '60%',
            dataType: 'number',
            search: true,
            hide: true,
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '会员名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'nickname',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.nickname) return '';
              return `${row.nickname}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataMemberApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              console.log(page, value, data);
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataMemberApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'nickname',
              value: 'id'
            },
            rules: [
              {
                required: true,
                message: '请选择推荐人',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'input',
            label: '客服编号',
            span: 12,
            display: true,
            prop: 'services'
          },
          {
            label: '佣金规则',
            prop: 'rebate_rule_id',
            type: 'table',
            span: 12,
            dialogWidth: '60%',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataRelateRuleApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataRelateRuleApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'title',
              value: 'id'
            },
            rules: [
              {
                required: true,
                message: '请选择产品',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: dataStatus,
            span: 12,
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status',
            required: true,
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ]
          },
          {
            label: '佐证材料',
            prop: 'material',
            type: 'upload',
            limit: 1,
            span: 24,
            addDisplay: true,
            editDisplay: true,
            propsHttp: {},
            dataType: 'array',
            listType: 'picture-card',
            accept: 'jpg/png',
            tip: '只能上传jpg/png文件，且不超过500kb',
            action: `${this.$baseUrl}system/upload`,
            oss: 'ali'
          },
          {
            type: 'textarea',
            label: '说明',
            span: 24,
            display: true,
            prop: 'remark',
            maxlength: 500,
            minRows: 3,
            maxRows: 6,
            showWordLimit: true
          }
        ],
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        selection: false,
        delBtn: false,
        editBtn: false,
        column: [
          {
            label: '流水号',
            prop: 'batch',
            type: 'input',
            addDisplay: 'true',
            editDisplay: 'true'
          },
          {
            label: '贷款产品',
            prop: 'product'
          },
          {
            label: '公司名称',
            prop: 'company'
          },
          {
            label: '贷款人',
            prop: 'guest'
          },
          {
            label: '推荐人',
            prop: 'referrer_name'
          },
          {
            label: '贷款金额(万)',
            prop: 'amount'
          },
          {
            label: '放款日期',
            prop: 'date_loan',
            type: 'date',
            hide: true
          },
          {
            label: '到期日期',
            prop: 'end_date',
            type: 'date',
            hide: true
          },
          {
            label: '借贷周期',
            prop: 'period',
            hide: true,
            type: 'timerange'
          },
          {
            label: '利率',
            prop: 'rate',
            hide: true
          },
          {
            label: '状态',
            type: 'radio',
            value: 0,
            dicData: dataStatus,
            prop: 'status'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchOption: {
        column: [
          
          {
            label: '推荐人',
            prop: 'referrer',
            type: 'table',
            span: 8,
            dialogWidth: '60%',
            dataType: 'number',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '会员名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'nickname',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.nickname) return '';
              return `${row.nickname}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataMemberApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              console.log(page, value, data);
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataMemberApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'nickname',
              value: 'id'
            }
          },
          {
            label: '贷款产品',
            prop: 'product_id',
            type: 'table',
            span: 8,
            dialogWidth: '60%',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataProductApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataProductApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'title',
              value: 'id'
            }
          },
          {
            label: '佣金规则',
            prop: 'rebate_rule_id',
            type: 'table',
            span: 8,
            dialogWidth: '60%',
            children: {
              border: true,
              column: [
                {
                  label: '编号',
                  prop: 'id',
                  width: 80
                },
                {
                  label: '名称',
                  search: true,
                  searchSpan: 24,
                  prop: 'title',
                  align: 'left'
                }
              ]
            },
            formatter: (row) => {
              if (!row.title) return '';
              return `${row.title}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                const postData = {
                  id: value
                };
                dataRelateRuleApi.getOne.r(postData).then(res => {
                  callback({
                    ...res.data
                  });
                });
                return;
              }
              const postData = {
                page: page.currentPage,
                pageSize: page.pageSize,
                ...data
              };
              dataRelateRuleApi.getList.r(postData).then(res => {
                callback({
                  total: res.data.total,
                  data: res.data.list
                });
              });
            },
            props: {
              label: 'title',
              value: 'id'
            }
          },
          {
            type: 'select',
            label: '状态',
            value: '',
            dicData: dataStatus,
            span: 8,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'status'
          },
          {
            type: 'daterange',
            label: '日期范围',
            span: 8,
            display: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          }
        ],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '搜索',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false
      },
      searchData: {
        starttime: '',
        endtime: ''
      },
      rebateOption: {
        total: 50,
        align: 'center',
        ...this.$store.getters.tableConfig,
        card: false,
        menu: false,
        addBtn: false,
        filterBtn: false,
        selection: false,
        column: [
          {
            label: '产品',
            prop: 'product'
          },
          {
            label: '类型',
            row: true,
            type: 'radio',
            value: 0,
            dicData: [
              {
                label: '交易佣金',
                value: 1
              },
              {
                label: '团队佣金',
                value: 2
              }
            ],
            prop: 'type'
          },
          {
            label: '受益人',
            prop: 'member'
          },
          {
            label: '佣金规则',
            prop: 'rebate_rule'
          },
          {
            label: '金额',
            prop: 'amount'
          },
          {
            
            type: 'radio',
            label: '状态',
            value: 0,
            dicData: [
              {
                label: '已作废',
                value: 0
              },
              {
                label: '待发放',
                value: 1
              },
              {
                label: '发放中',
                value: 2
              },
              {
                label: '已发放',
                value: 3
              }
            ],
            span: 8,
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      rebateData: [],
      logOption: {
        total: 50,
        align: 'center',
        ...this.$store.getters.tableConfig,
        card: false,
        menu: false,
        addBtn: false,
        filterBtn: false,
        selection: false,
        column: [
          {
            label: '操作类型',
            prop: 'remark'
          },
          {
            label: '操作说明',
            prop: 'action'
          },
          {
            label: '状态',
            prop: 'result'
          },
          {
            // 创建者 create_by
            label: '操作员',
            prop: 'create_user'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            prop: 'create_time'
          }
        ]
      },
      logData: []
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.starttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.endtime = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 佣金
    rebateDialogList(row) {
      this.getRebate(row);
      this.dialogVisible = true;
    },
    rowView(row) {
      this.getInfo(row);
      // 加载佣金
      this.getRebate(row);
      this.getActionLog(row);
      this.drawerInfoVisible = true;
    },
    drawerInfoClose() {
      this.drawerInfoVisible = false;
    },
    // 添加
    addDialogForm(row) {
      if (row.id !== undefined) {
        this.getInfo(row);
      }
      this.drawerVisible = true;
    },
    drawerClose() {
      this.drawerVisible = false;
      this.$refs.form.resetForm();
    },
    getInfo(row) {
      this.formDa = {
        bank: {
          title: ''
        },
        product: {
          title: ''
        }
      };
      dataApi.getOne.r({ id: row.id }).then(res => {
        this.formDa = res.data;
        // 设置product回显
        if (res.data.product) {
          this.productList.push(res.data.product);
        }
        // 推荐人referrer回显
        if (res.data.referrer) {
          this.memberList.push(res.data.referrerInfo);
        }
        // 佣金规则rule回显
        if (res.data.rule) {
          this.rebateRuleList.push(res.data.rule);
        }
        // 设置放款日期
        if (res.data.date_loan === '0000-00-00') {
          const date = new Date();
          this.formDa.date_loan = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        }
        // 设置可选状态
        for (let i = 0; i < dataStatus.length - 1; i++) {
          if (res.data.status > i) {
            dataStatus[i].disabled = true;
          } else {
            dataStatus[i].disabled = false;
          }
        }
        // 设置是否可编辑
        if (res.data.status >= 4) {
          this.formOp.disabled = true;
          this.formOp.submitBtn = false;
        } else {
          this.formOp.disabled = false;
          this.formOp.submitBtn = true;
        }
        if (res.data.material) {
          this.formDa.material = this.formDa.material.split(',');
        }
      });
    },
    // 获取列表
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    searchExcel() {
      const postData = {
        ...this.searchData
      };
      dataApi.exportFile.r(postData).then(res => {
        console.log('接口返回', res);
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          window.location.href = res.data.file;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载'
          });
        });
      });
    },
    // 获取佣金
    getRebate(row) {
      const postData = {
        page: 1,
        pageSize: 50,
        trading_id: row.id
      };
      dataRebateApi.getList.r(postData).then(res => {
        this.rebateData = res.data.list;
      });
    },
    rebateRuleChangeSelect() {
      
    },
    getRebateRule(query) {
      if (query !== '') {
        const postData = {
          title: query
        };
        dataRelateRuleApi.getList.r(postData).then(res => {
          this.rebateRuleList = res.data.list;
        });
      }
    },
    productChangeSelect() {
      
    },
    getProduct(query) {
      console.log('query', query);
      if (query !== '') {
        const postData = {
          title: query
        };
        dataProductApi.getList.r(postData).then(res => {
          this.productList = res.data.list;
        });
      }
    },
    referrerChangeSelect() {
      
    },
    getReferrer(query) {
      console.log('query', query);
      if (query !== '') {
        const postData = {
          nickname: query
        };
        dataMemberApi.getList.r(postData).then(res => {
          this.memberList = res.data.list;
        });
      }
    },
    // 获取操作日志
    getActionLog(row) {
      const postData = {
        page: 1,
        pageSize: 50,
        trading_id: row.id
      };
      dataApi.actionLog.r(postData).then(res => {
        this.logData = res.data.list;
      });
    },
    // 团队切换
    handleTabClick(tab, event) {
      console.log(tab.name, tab, event);
    }
  }
};
