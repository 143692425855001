<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      :search.sync="searchData"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
      v-model="member"
    >
      
      <template slot-scope="{type,size,row,index}" slot="menu">
        <el-button icon="el-icon-view" :size="size" :type="type" @click.stop="rowView(row,index)">查看</el-button>
      </template>
    </avue-crud>
    
    <el-drawer
      title="会员详情"
      :before-close="drawerClose"
      :visible.sync="drawerVisible"
      direction="ttb"
      size="100%"
    >
      <div class="details">
        <!--用户详情-->
        <div class="details-header">
          <div class="details-title">基础信息</div>
        </div>
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">会员名称:</div>
            <div class="details-view-item-value">{{member.nickname}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">手机号:</div>
            <div class="details-view-item-value">{{member.mobile}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">openid:</div>
            <div class="details-view-item-value">{{member.openid || '未取得'}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">身份证:</div>
            <div class="details-view-item-value">{{member.idcard}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">所属分组:</div>
            <div class="details-view-item-value">{{member.group}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">会员级别:</div>
            <div class="details-view-item-value">{{member.rank}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">交易佣金:</div>
            <div class="details-view-item-value">￥{{member.balance}}元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">团队佣金:</div>
            <div class="details-view-item-value">￥{{member.team_balance}}元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">推荐人:</div>
            <div class="details-view-item-value">{{member.referrer_text}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">状态:</div>
            <div class="details-view-item-value">{{member.status_text}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{member.create_time}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">更新日期:</div>
            <div class="details-view-item-value">{{member.update_time}}</div>
          </div>
        </div>
        <div class="details-header">
          <div class="details-title">团队详情</div>
        </div>
        <!--团队详情-->
        <div class="details-view flex-column">
          <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane label="全部" name="1"></el-tab-pane>
            <el-tab-pane label="直系会员" name="2"></el-tab-pane>
            <el-tab-pane label="非直系会员" name="3"></el-tab-pane>
          </el-tabs>
          <avue-crud
            ref="child"
            :data="childData"
            :option="childOption"
            :page.sync="childPage"
            @on-load="getChildData"
            @refresh-change="getChildData"
          >
          </avue-crud>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
