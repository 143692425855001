<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @on-load="onLoad"
      :search.sync="searchData"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >  
      
      <template slot-scope="{type,size,row,index}" slot="menu">
        <el-button icon="el-icon-view" :size="size" :type="type" @click.stop="addDialogForm(row,index)">查看</el-button>
      </template>    
    </avue-crud>
    <el-dialog title="客户进件" size="70%" 
      :close-on-click-modal="false" 
      :visible.sync="dialogVisible"
      @close="dialogCloseCallback"
    >
      <avue-form ref="form" v-model="formDa" :option="formOp" @submit="rowUpdate">
        <template slot-scope="{disabled}" slot="bank_id">
          <el-select
            v-model="formDa.bank_id"
            filterable
            remote
            placeholder="请输入关键词"
            suffix-icon="el-icon-search"
            :disabled="disabled"
            @visible-change="bankChangeSelect"
            :remote-method="getBank">
            <el-option
              v-for="item in bankList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
            
          </el-select>
        </template>
        <template slot-scope="{disabled}" slot="product_id">
          <el-select
            v-model="formDa.product_id"
            filterable
            remote
            placeholder="请输入关键词"
            :disabled="disabled"
            @visible-change="bankChangeSelect"
            :remote-method="getProduct">
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
