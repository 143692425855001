import http from '@/utils/http';

/**
 * 客户管理  
 */ 
export default {
  getList: {
    p: 'post,/mpc/member/getlist',
    r: (data) => http({ url: '/mpc/member/getlist', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/member/getone',
    r: (data) => http({ url: '/manage/member/getone', method: 'post', data })
  },
  getChild: {
    p: 'post,/manage/member/child',
    r: (data) => http({ url: '/manage/member/child', method: 'post', data })
  },
  exportFile: {
    p: 'post,/manage/member/export',
    r: (data) => http({ url: '/manage/member/export', method: 'post', data })
  }
};
