import './list.scss';
import dataApi from '@/api/custmoer/client.js';

export default {
  name: 'member-list',
  components: {},
  data() {
    return {
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100],
        currentPage: 1
      },
      memberList: [],
      activeName: '1',
      drawerVisible: false,
      referrerInputTabel: { 
        column: {
          children: {
            border: true,
            column: [
              {
                label: '编号',
                prop: 'id',
                width: 80
              },
              {
                label: '会员名称',
                search: true,
                searchSpan: 24,
                prop: 'nickname',
                align: 'left'
              }
            ]
          }
        },
        props: {
          label: 'nickname',
          value: 'id'
        }
      },
      tableData: [],
      tbleOption: {
        total: 10,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        delBtn: false,
        editBtn: false,
        selection: false,
        column: [
          {
            label: '名称',
            prop: 'nickname',
            type: 'input',
            search: true
          },
          {
            label: '推荐人',
            prop: 'referrer_text',
            type: 'input'
          },
          {
            label: '推荐人',
            prop: 'referrer',
            hide: true,
            span: 12,
            formslot: true,
            editDisplay: true
          },     
          // {
          //   type: 'select',
          //   label: '会员组',
          //   span: 12,
          //   display: true,
          //   prop: 'member_group_id',
          //   dicFlag: true,
          //   filterable: true,
          //   search: true,
          //   dicUrl: `${this.$baseUrl}general/get_member_group`,
          //   dicMethod: 'get'
          // },
          {
            type: 'select',
            label: '会员级别',
            span: 12,
            display: true,
            prop: 'member_rank_id',
            dicFlag: true,
            filterable: true,
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_member_rank`,
            dicMethod: 'get'
          },
          {
            label: '手机号',
            prop: 'mobile',
            type: 'input',
            search: true
          },
          {
            label: '交易奖金',
            prop: 'balance',
            type: 'input',
            editDisplay: false
          },
          {
            label: '团队奖金',
            prop: 'team_balance',
            type: 'input',
            editDisplay: false
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status'
          },
          {
            // 创建时间 create_time
            label: '注册时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {
        datetime: []
      },
      childMap: {
        is_child: true,
        member_id: 0,
        dis_type: 1
      },
      childPage: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100],
        currentPage: 1
      },
      childOption: {
        total: 10,
        align: 'center',
        ...this.$store.getters.tableConfig,
        card: false,
        menu: false,
        addBtn: false,
        filterBtn: false,
        selection: false,
        column: [
          {
            label: '会员名称',
            prop: 'nickname'
          },
          {
            label: '会员等级',
            prop: 'rank'
          },
          {
            label: '会员分组',
            prop: 'group'
          },
          {
            label: '推荐人',
            prop: 'referrer_text'
          },
          {
            label: '手机号',
            prop: 'mobile'
          },
          {
            label: '客户数',
            prop: 'guest_count'
          },
          
          {
            label: '有效进件',
            prop: 'trading_count'
          },
          {
            label: '直推会员',
            prop: 'lower_count'
          }
        ]
      },
      childData: [],
      formDa: {},
      formOp: {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '会员名称',
            prop: 'nickname',
            type: 'input',
            search: true,
            addDisplay: true,
            editDisplay: true,
            required: true,
            rules: [
              {
                required: true,
                message: '会员名称不能为空'
              }
            ]
          },
          {
            label: '推荐人',
            prop: 'referrer',
            hide: true,
            span: 12,
            formslot: true,
            editDisplay: true
          },
          {
            type: 'select',
            label: '会员组',
            span: 12,
            display: true,
            prop: 'member_group_id',
            dicFlag: true,
            filterable: true,
            search: true,
            dicUrl: `${this.$baseUrl}general/get_member_group`,
            dicMethod: 'get'
          },
          {
            type: 'select',
            label: '会员级别',
            span: 12,
            display: true,
            prop: 'member_rank_id',
            dicFlag: true,
            filterable: true,
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_member_rank`,
            dicMethod: 'get'
          },
          {
            label: '手机号',
            prop: 'mobile',
            type: 'input',
            search: true,
            span: 12,
            editDisplay: true,
            required: true,
            rules: [
              {
                required: true,
                message: '手机号不能为空'
              }
            ]
          },
          {
            label: '身份证号码',
            prop: 'idcard',
            type: 'input',
            span: 12,
            hide: true,
            editDisplay: true,
            required: true,
            rules: [
              {
                required: true,
                message: '身份证号码不能为空'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true,
            span: 12
          }
        ]
      },
      member: {}
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.starttime = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.endtime = datetime[1];
      }
      // delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    searchExcel() {
      const postData = {
        ...this.searchData
      };
      dataApi.exportFile.r(postData).then(res => {
        console.log('接口返回', res);
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          window.location.href = res.data.file;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载'
          });
        });
      });
    },
    drawerClose() {
      this.activeName = '1';
      this.childData = [];
      this.childPage.currentPage = 1;
      this.childMap = {
        is_child: true,
        member_id: 0,
        dis_type: 1
      };
      console.log(this.formDa);
      this.drawerVisible = false;
    },
    dialogCloseCallback() {
      this.productList = [];
      this.bankList = [];
    },
    
    referrerOnLoad({ page, value, data }, callback) {
      // 首次加载去查询对应的值
      if (value) {
        const postData = {
          id: value
        };
        dataApi.getOne.r(postData).then(res => {
          callback({
            ...res.data
          });
        });
        return;
      }
      const postData = {
        page: page.currentPage,
        pageSize: page.pageSize,
        ...data
      };
      dataApi.getList.r(postData).then(res => {
        callback({
          total: res.data.total,
          data: res.data.list
        });
      });
    },
    referrerFormatter(row) {
      if (!row.nickname) return '';
      return `${row.nickname}`;
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then(res => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    rowEdit(row) {
      this.dialogVisible = true;
      this.formDa = row;
      this.memberList = [{
        id: row.referrer,
        nickname: row.referrer_text
      }];
      if (row.referrer > 0) {
        this.memberList.push({
          id: 0,
          nickname: '自助注册'
        });
      }
    },
    getMember(query) {
      const postData = {
        nickname: query,
        page: 1,
        pageSize: 10
      };
      dataApi.getList.r(postData).then(res => {
        this.memberList = res.data.list;
        this.memberList.push({
          id: 0,
          nickname: '自助注册'
        });
      });
    },
    rowView(row) {
      this.childMap = {
        is_child: true,
        member_id: row.id,
        dis_type: 1
      };
      this.getInfo(row);
      this.drawerVisible = true;
    },
    getInfo(row) {
      dataApi.getOne.r({ id: row.id }).then(res => {
        this.member = res.data;
      });
      this.getChildData();
    },
    memberChangeSelect() {
      
    },
    formatMemberList() {
      // 格式化推荐人列表

    },
    // 团队切换
    handleTabClick(tab) {
      if (tab.name !== this.childMap.dis_type) {
        this.childMap.dis_type = tab.name;
        this.getChildData();
      }
    },
    getChildData() {
      const postData = {
        page: this.childPage.currentPage,
        pageSize: this.childPage.pageSize,
        ...this.childMap
      };
      dataApi.getChild.r(postData).then(res => {
        this.childData = res.data.list;
        this.childPage.total = res.data.total;
      });
    }
  }
};
