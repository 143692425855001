import http from '@/utils/http';
 
/**
 * 进件接口
 */
export default {
  getList: {
    p: 'post,/mpc/guestinto/getuserlist',
    r: (data) => http({ url: '/mpc/guestinto/getuserlist', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/guestinto/getone',
    r: (data) => http({ url: '/manage/guestinto/getone', method: 'post', data })
  },
  actionLog: {
    p: 'post,/manage/trading/logs',
    r: (data) => http({ url: '/manage/trading/logs', method: 'post', data })
  },
  exportFile: {
    p: 'post,/manage/trading/export',
    r: (data) => http({ url: '/manage/trading/export', method: 'post', data })
  }
};
